export interface Website {
  _id: string;
  domain: string;
  apiKey: string;
}

export interface PhoneNumber {
  phone: string;
  dialCode: string;
}

export interface User {
  phoneNumber: PhoneNumber;
  firstName: string;
  lastName: string;
}

export interface PhoneNumberDetail {
  phone?: string;
  dialCode?: string;
  fullPhone?: string;
}
export interface AddressDetail {
  zip?: any;
  street?: any;
  city?: any;
  state: string;
  country: string;
}
export interface UserDetail {
  phoneNumber: PhoneNumberDetail;
  addressDetail: AddressDetail;
  verifyStatus: string;
  _id: string;
  dateOfBirth: string;
  documentType: string;
  firstName: string;
  lastName: string;
}

export interface CommonApiParam {
  dateRangeType?: string;
  page?: number;
  pageSize?: number;
  status?: string;
  uuid?: string;
  websiteId?: string;
  website?: string;
  startDate?: number;
  endDate?: number;
  timezone?: string;
  lang?: string;
  registrationsDate?: string;
  businessName?: string;
  firstName?: string;
  lastName?: string;
  phone?: string;
  sort?: string;
}

export interface CreateCompany {
  key?: string;
  phoneNumber?: PhoneNumber;
  userEmail?: string;
  notifyStatus?: boolean;
}

export interface Company {
  phoneNumber: PhoneNumber;
  status: string;
  _id: string;
  firstName: string;
  lastName: string;
  email: string;
  businessName: string;
  country: string;
  city: string;
  state: string;
  website: string;
  dateCreated: Date;
  dateUpdated: Date;
  registrationsDate: Date;
}
export interface IStatus {
  status: string;
}
export interface ListCompaniesResponse {
  data: Company[];
  page: number;
  pageSize: number;
  totalItem: number;
  totalPage: number;
}
export interface PlaidConfig {
  template: string;
  enablePII: boolean;
  idvPrice: number;
}
export interface VouchedConfig {
  idvPrice: number;
}
export interface DentityConfig {
  method: string;
  price: number;
}
export interface UpdatePlaidConfig {
  websiteIds: string[];
  idvMethod: string;
  plaid?: {
    template: string;
    enablePII: boolean;
    idvPrice: number;
  };
  vouched?: {
    idvPrice: number;
  };
  trinsicConnect?: {
    idvPrice: number;
  };
}
export interface UpdateDentityConfig {
  websiteIds: string[];
  dentityDataConfig: {
    method: string;
    price: number;
  };
}

export interface UpdateFaceTecConfig {
  websiteIds: string[];
  faceScanConfig: {
    uniquenessConfig: {
      method: string;
      enablePII?: boolean;
      price: number;
    };
    uniquenessPresentConfig: {
      method: string;
      enablePII?: boolean;
      price: number;
    };
  };
}
export interface IPlaidConfig {
  idvConfig: {
    method: string;
    plaidConfig: {
      enablePII: boolean;
      template: string;
    };
  };
}
export interface IVerifyDialConfig {
  dateCreated: string;
  dateUpdated: string;
  idvConfig: {
    plaidConfig: {
      enablePII: boolean;
      template: string;
    };
    method: string;
    price: number;
  };
  name: string;
  __v: number;
  _id: string;
}

export interface WebsiteDetail {
  ageSetting: string;
  apiKey: string;
  buttonHtml: string;
  buttonName: string;
  dateCreated: string;
  dateUpdated: string;
  domain: string;
  email: string;
  logo: string;
  notifyUserApproved: boolean;
  notifyUserDenied: boolean;
  openingMsg: string;
  partnerId: string;
  plaidConfig: PlaidConfig | null;
  vouchedConfig: VouchedConfig | null;
  dentityDataConfig: DentityConfig | null;
  siteName: string;
  status: string;
  successMsg: string;
  __v: number;
  _id: string;
  idvMethod: string;
  verificationMethod: string;
  identityLevel?: string;
  verificationLevel?: string;
  faceScanConfig?: any;
}

export enum METHOD_IDV {
  VOUCHED = 'VOUCHED',
  PLAID = 'PLAID',
  TRINSIC = 'TRINSIC_CONNECT',
}

export interface ListWebsitesResponse {
  data: WebsiteDetail[];
  page: number;
  pageSize: number;
  totalItem: number;
  totalPage: number;
}
export interface CompanyDetail {
  phoneNumber: PhoneNumber;
  status: string;
  _id: string;
  firstName: string;
  lastName: string;
  email: string;
  businessName: string;
  country: string;
  city: string;
  state: string;
  website: string;
  dateCreated: Date;
  dateUpdated: Date;
  registrationsDate: Date;
  user: UserDetail;
  verificationMessage: string;
  deactivatedProgrammatic: boolean;
  enableKycCredential?: boolean;
}

export interface ListWebsite {
  data: Website[];
  totalItem: number;
  page: number;
  pageSize: number;
  totalPage: number;
}

export interface CompaniesStoreModel {
  loading: boolean;
  listCompanies: ListCompaniesResponse;
  companyDetail: CompanyDetail;
  dataCharts: any;
  params: CommonApiParam;
}

export interface LoginRequestParams {
  username: string;
  password: string;
}

export interface LoginSuccessResponse {
  token?: string;
}

export interface Chart {
  value: number;
  priority: number;
}

export interface GetStatisticResponsive {
  last6Months: {
    [key: string]: Chart;
  };
  rating: { totalVerified: number; totalFailed: number };
  verificationsVolume: { [key: string]: Chart };
}

export interface CreateCompanyResponsive {
  _id: string;
  status: string;
  website: {
    _id: string;
    domain: string;
  };
  dateUpdated: string;
  user: {
    phoneNumber: {
      phone: string;
      dialCode: string;
    };
    firstName: string;
    lastName: string;
  };
}
